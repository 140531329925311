<template>
  <div class="baseBtra">

    <div class="conflex">
      <!-- <template v-for="(elem, idx) in btra"> -->
      <template>
      <div v-for="(elem, idx) in btra" :key="idx">

        <div v-if="elem.slot">
          <slot :name="getNameSlot(elem.accion)" :item="elem" :show="show(elem)" :disabled="disabled(elem)" />
        </div>

        <div v-else>
          <div v-if="!elem.menu">
            <v-btn
              v-if="show(elem)"
              v-bind="design"
              :key="idx"
              :style="styBtn(elem, idx)"
              :title="elem.title? elem.title : (elem.btn? $cfe.btn[elem.btn].tooltip : '')"
              :disabled="disabled(elem)"
              @click="eventBtra(elem.accion)">

              <!-- Label -->
              <template v-if="design['label'] && elem.texto">
                <span :style="elem.styT? elem.styT : ''">{{ elem.texto }}</span>
              </template>

              <!-- Icono -->
              <v-icon v-if="elem.icono || elem.btn" v-bind="design.icons" :style="elem.styI? elem.styI : ''">
                {{ elem.icono? elem.icono : $cfe.btn[elem.btn].icon }}
              </v-icon>

              <!-- Icono SVG -->
              <img v-if="elem.svg" :src="elem.svg" />

              <!-- Badge -->
              <div v-if="elem.badge">
                <v-badge v-bind="$cfe.styles.badge" :content="elem.badge()"></v-badge>
              </div>

            </v-btn>
          </div>

          <div v-else>
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="show(elem)"
                  v-bind="design"
                  v-on="on"
                  :key="idx"
                  :style="styBtn(elem, idx)"
                  :title="elem.title? elem.title : (elem.btn? $cfe.btn[elem.btn].tooltip : '')"
                  :disabled="disabled(elem)">

                    <!-- Icono -->
                    <v-icon v-if="elem.icono || elem.btn" v-bind="design.icons" :style="elem.styI? elem.styI : ''">
                      {{ elem.icono? elem.icono : $cfe.btn[elem.btn].icon }}
                    </v-icon>

                    <!-- Icono SVG -->
                    <img v-if="elem.svg" :src="elem.svg" />

                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in elem.menu"
                  :key="index"
                  dense
                  @click="clickMenu(item)">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
      </template>
    </div>
  </div>
</template>



<script>

  export default {
    name: "baseBtra",
    props: {
      btra: { type: [Array, Object], default: null, required:true },
      modulo: { type: Object, default: null },
      showDisabled: { type: Number, default: 1 }
    },

    data() {
      return {
        design:{}
      };
    },


    created() {
      if (!this.modulo) return;
      this.design= this.modulo.design;
    },


    methods: {
      // gestor de eventos
      eventBtra(accion) {
        console.log("*** eventBtra. Emit btra: ", accion, " ***");
        this.$emit("onEvent", { accion });
      },


      // muestro el botón SÍ o NO (independientemente si está disabled)
      show(elem) {
        switch (this.showDisabled) {
        case 0:
          if (typeof elem.view!== 'undefined') return Boolean(elem.view);
          if (typeof elem.show!== 'undefined') return Boolean(elem.show);
          return true;

        case 1:
          if (typeof elem.view!== 'undefined') return Boolean(elem.view);
          if (typeof elem.show!== 'undefined') return true;
          return true;
        }
      },


      // deshabilito el botón SÍ o NO
      disabled(elem) {
        switch (this.showDisabled) {
        case 0:
          if (typeof elem.show!== 'undefined' && !elem.show) return true;
          if (typeof elem.disabled!== 'undefined') return Boolean(elem.disabled);
          return false;

        case 1:
          if (typeof elem.show!== 'undefined' && !elem.show) return true;
          if (typeof elem.disabled!== 'undefined') return Boolean(elem.disabled);
          return false;
        }
      },


      // devuelvo el nombre de la acción para identificar el slot
      getNameSlot(accion) {
        // (0:'ver', 1:'nuevo', 2:'editar', 3:'borrar', 4:'guardar', 5:'cancelar', 6:'salir')
        const mtoOptions= ['ver', 'nuevo', 'editar', 'borrar', 'guardar', 'cancelar', 'salir'];
        return (/^([0-9])*$/.test(accion))? mtoOptions[accion] : accion;
      },


      //
      styBtn(elem) {
        // obtengo el nº de carácteres que tiene el texto a mostrar en el badge para meterle un margin a su derecha
        let chars= '';
        chars= elem.badge? String(elem.badge()).length : 1;
        chars= chars< 2? 2 : chars;

        // defino estilo
        let style= '';
        style+= Object.prototype.hasOwnProperty.call(elem, 'styB')? elem.disabled? '' : elem.styB() : 'margin-left:5px;';
        if (elem.badge) style+= elem.badge? `margin-right:${chars*10}px` : '';
        return style;
      },


      clickMenu(item) {
        this.$emit('onEvent', item);
      }

    },

  };
</script>
